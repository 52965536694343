import React, { useRef, useState, useEffect } from "react";
import { BiLocationPlus } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Pics from "./components/Pics";

const minFlip = 853;

export default function App() {
  const [mounted, setMounted] = useState(null);
  const [minView, setMinView] = useState(window.innerWidth < minFlip);

  useEffect(() => {
    function handleResize() {
      setMinView(window.innerWidth < minFlip);
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (mounted === null) {
      setMounted(true);
    }
  }, [mounted]);

  const services = [
    {
      title: "General Services",
      link: "general-services",
      ref: useRef(),
      time: "1+ Hour",
    },
    {
      title: "Road Side Tire Change",
      link: "road-side-tire-change",
      time: "30 Minutes",
      ref: useRef(),
    },
    {
      title: "Off Road Recovery",
      link: "road-side-tire-recovery",
      ref: useRef(),
      time: "Time Varies",
    },
    {
      title: "Air Conditioning",
      link: "air-conditioning",
      time: "1+ Hour",
      ref: useRef(),
    },
    {
      title: "Timing Belt Replacement",
      link: "timing-belt-replacement",
      time: "4+ Hours",
      ref: useRef(),
    },
    {
      title: "Oil Change",
      link: "oilchange",
      time: "30 Minutes",
      ref: useRef(),
    },
    {
      title: "Brake Services",
      link: "brake-services",
      time: "45 Minutes",
      ref: useRef(),
    },
  ];
  return (
    <div className="App">
      <Header />
      <div className="app-content">
        <header className="App-header">
          <div
            style={{ width: minView ? "100vw" : "50vw" }}
            className="header-left"
          >
            <div className="app-title">Flash Auto Repair & Recovery</div>
            <div className="app-title-location">
              <BiLocationPlus style={{ marginRight: "15px" }} /> Raleigh, Durham
              NC
            </div>
          </div>
        </header>

        <div className="app-body">
          <div className="cards-body">
            <div className="app-body-content">
              <div className="copy-container">
                Do you need your car towed at a reasonable price? Give me a call
                and let me help you. Whether you're broken down or just need
                your car moved - I'm here to help at an affordable price. I
                offer full Mobile Repair service, off road recovery, towing and
                more! Call
                <a
                  style={{
                    color: "blue",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                  href="tel:9199083739"
                >
                  919-908-3739
                </a>{" "}
                today and let me help you!
              </div>

              <h1>Services</h1>
              {services.map((x) => (
                <div
                  onClick={() => window.scrollTo(0, x.ref.current.offsetTop)}
                  className="service-cards"
                >
                  <div className="dflex"> {x.title}</div>
                  <div className="dflex-right">
                    <BsCheckCircleFill
                      style={{ color: "green", marginRight: "8px" }}
                    />
                  </div>
                </div>
              ))}
              <div className="copy-container m-top-25">
                If you're broken down on the road, had a fender bender, need a
                winch out from a ditch or off-road recovery, or simply want to
                just want to relocate your vehicle im here to help 24/7 at an
                affordable rate. Give me a call and let me see how I can assist
                your vehicle. I have all the equipment to get the job done
                safely and right, located in the triangle and outskirts of Raleigh Durham North Carolina.
              </div>

              <div className="copy-container m-top-25">
                Have a project that needs welding or fabing? Give me a call and
                let me know how I can help!
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <div className="help-flyout">
        {mounted ? (
          <div>
            Stuck? Call me at{" "}
            <a style={{ color: "white" }} href="tel:9199083739">
              919-908-3739
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
