import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const pic1 = require("../images/1.jpeg");
const pic2 = require("../images/2.jpeg");
const pic3 = require("../images/3.jpeg");
const pic4 = require("../images/4.jpeg");
const pic5 = require("../images/5.jpeg");
const pic6 = require("../images/6.jpeg");

export default function Pics({}) {
  return (
      <div className="caro-container"> 
    <Carousel showThumbs={false}>
      <div>
        <img className="size-pics" src={pic2} />
      </div>
      <div>
        <img className="size-pics" src={pic3} />
      </div>
    </Carousel>
    </div>
  );
}
