import React from "react";
import { BsFacebook } from "react-icons/bs";

export default function FacebookButton({ style }) {
  const s = style || {};
  return (
    <a href="https://www.facebook.com/Flashs-Recovery-Mobile-Repair-104975298622087/">
      <BsFacebook style={{ height: "35px", width: "35px", ...s }} />
    </a>
  );
}
