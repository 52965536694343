import React from "react";

export default function CallButton() {
  return (
    <div className="call-button">
      {/* <AiOutlinePhone style={{ marginRight: "15px" }} />{" "} */}
      <a style={{ color: "white" }} href="tel:9199083739">
       Contact
      </a>
    </div>
  );
}
