import React from "react";
import CallButton from "./CallButton";
import "./components.css";
import FacebookButton from "./FacebookButton";

export default function Header() {
  return (
    <div className={`header`}>
      <div className="header-container">
        <FacebookButton />
        <div className="dflex-right m-right-25">
          <CallButton />
        </div>
      </div>
    </div>
  );
}
