import React from "react";
import { AiOutlinePhone } from "react-icons/ai";
import FacebookButton from "./FacebookButton";

export default function Footer() {
  return (
    <div className="footer">
      <div className="dflex title-footer">Flash Auto Repair & Recovery </div>
    </div>
  );
}
